<template>
  <div style="width: 100%;">
    <v-card v-if="card" :outlined="outlined" style="width: 100%;">
      <v-card-text style="width: 100%;" class="d-flex flex-row">
        <span v-if="mainLoader">
          <v-progress-circular
            indeterminate
            color="info"
            small
          ></v-progress-circular>
        </span>
        <div v-else class="d-flex flex-row flex-wrap">
          <div v-for="(button, index) in dynamicButtons" :key="index" class="d-flex flex-row">
            <v-tooltip :disabled="!button.description" bottom max-width="200px" open-delay="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  :fab="button.fab"
                  :loading="btnLoaders[index]"
                  :color="button.color" 
                  :class="button.classes" 
                  :small="button.size == 'small'" 
                  :x-small="button.size == 'x-small'" 
                  :large="button.size == 'large'" 
                  :x-large="button.size == 'x-large'"
                  :disabled="btnDisabled(button)"
                  @click="confirm(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="button.fabIcon">{{ button.fabIcon }}</v-icon>
                  <span class="ml-1" v-if="!button.fab">{{ button.name }}</span>
                </v-btn>
              </template>
              <span>{{ button.description?button.description:""}}</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div v-else class="d-flex flex-row mr-1">
      <span v-if="mainLoader">
        <v-progress-circular
          indeterminate
          color="info"
          small
        ></v-progress-circular>
      </span>
      <div v-else class="d-flex flex-row">
        <div v-for="(button, index) in dynamicButtons" :key="index" class="d-flex flex-row">
          <v-tooltip :disabled="!button.description" bottom max-width="200px" open-delay="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                :fab="button.fab"
                :loading="btnLoaders[index]"
                :color="button.color" 
                :class="button.classes" 
                :small="button.size == 'small'" 
                :x-small="button.size == 'x-small'" 
                :large="button.size == 'large'" 
                :x-large="button.size == 'x-large'"
                :disabled="btnDisabled(button)"
                @click="confirm(index)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="button.fabIcon">{{ button.fabIcon }}</v-icon>
                <span class="ml-1" v-if="!button.fab">{{ button.name }}</span>
              </v-btn>
            </template>
            <span>{{ button.description?button.description:button.name}}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      DYNP: {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex'
export default {
  name: 'dynamicButtonContainer',
  props: {
    containerId: String,
    data: Object
  },
  computed: {
    ...mapGetters([
      'getEndpoint',
    ]),
  },
  data: () => ({
    dynamicButtons: [],
    btnLoaders: [],
    mainLoader: true,
    snackObj: {
      text: '',
      state: false,
      color: ''
    },
    outlined: false,
    card: false,
  }),
  async mounted(){
    try {
      let res = await axios.get(`${this.getEndpoint}/api/dynamicButtonContainers/${parseInt(this.containerId)}`)
      if(res.data.error) throw res.data.error
    
      if(res.data.data && res.data.data.dynamicButtons && res.data.data.dynamicButtons.data){
        
        this.dynamicButtons = res.data.data.dynamicButtons.data
        this.outlined = res.data.data.metadata.outlined
        this.card = res.data.data.metadata.card

        for(let x = 0; x < this.dynamicButtons.length; x++){
          this.btnLoaders.push(false)
        }

      }

    } catch (error) {
      console.log(error.msg || error)
    } finally {
      this.mainLoader = false
    }
  },
  methods: {
    btnDisabled(button){
      let v = false
      let curr = false      
      let leftSide, rightSide

      let d = {
        ...this.data
      }

      if(Object.prototype.hasOwnProperty.call(d, "disabled")) return !!d.disabled

      for(let i of button.disabled){

        rightSide = i.value
        
        if(i.if == "STATUS") leftSide = this.data.status

        if(i.is == 'EQ'){
          if(leftSide == rightSide) curr = true
        }
        
        if(i.is == 'EQS'){
          if(leftSide === rightSide) curr = true
        }

        if(i.is == 'NEQ'){
          if(leftSide != rightSide) curr = true
        }

        if(i.is == 'LT'){
          if(leftSide < rightSide) curr = true
        }

        if(i.is == 'GT'){
          if(leftSide > rightSide) curr = true
        }

        if(i.is == 'LTE'){
          if(leftSide <= rightSide) curr = true
        }

        if(i.is == 'GTE'){
          if(leftSide >= rightSide) curr = true
        }

        if(i.concatOp == "AND"){
          v = v && curr          
        }else if(i.concatOp == "OR"){
          v = v || curr          
        }else{
          v = curr          
        }

      }
      return v
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async confirm(index){
      let button = this.dynamicButtons[index]
      this.btnLoaders[index] = true
      this.$forceUpdate()
      if(button.requestType && button.requestEndpoint){

        let obj = {
          method: button.requestType,
          action: button.requestEndpoint,
          id1: this.data.id1?this.data.id1:null,
          id2: this.data.id2?this.data.id2:null,
        }

        let res = await this.printHandler(obj, index)

        this.$emit('res', res);
      }
    },
    async printHandler(obj, index){ // {id, method, action}
      try {
        this.printHandlerLoading = true
        
        let uri = `${this.getEndpoint}/${obj.action.replace('/:id1', '').replace('/:id2', '')}`
        uri += `${obj.id1?'/'+obj.id1:''}`
        uri += `${obj.id2?'/'+obj.id2:''}`

        console.log(uri)

        let x = await axios[obj.method](uri)
        if(x.data.error) throw x.data.error

        console.log(x.data.data)
        
        let printWindow = open("","Printing")
        printWindow.document.write("")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.document.write("")
        },500)

        return x;

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.printHandlerLoading = false
        this.btnLoaders[index] = false
        this.$forceUpdate()
      }
    }
  }
}
</script>
